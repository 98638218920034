import { useState, useEffect } from 'react';
import { formatInTimeZone } from 'date-fns-tz';

const ConversationsPanel = () => {
  const COMPANY_ID = 'a20c1318-5126-4ecc-ae79-4d2b1e018537'; // Reemplaza con el ID de empresa adecuado si es necesario

  const [clients, setClients] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState(
    localStorage.getItem('selectedClientId') || null
  );
  const [conversations, setConversations] = useState([]);
  const [loadingClients, setLoadingClients] = useState(true);
  const [loadingConversations, setLoadingConversations] = useState(false);
  const [error, setError] = useState(null);

  // Función para parsear la fecha como UTC
  const parseDateAsUTC = (dateString) => {
    if (!dateString) return new Date();
    // Truncar a 3 dígitos en los milisegundos y agregar 'Z' para UTC
    const fixedDateString = dateString.slice(0, 23) + 'Z';
    return new Date(fixedDateString);
  };

  // Función para aplanar los objetos de DynamoDB a objetos planos
  const flattenClient = (client) => ({
    CreatedAt: parseDateAsUTC(client.CreatedAt?.S || ''),
    Id: client.Id?.S || '',
    Phone: client.Phone?.S || '',
    //Email: client.Email?.S || '',
    //Name: client.Name?.S || '',
    //Overview: client.Overview?.S || '',
  });

  // Función para formatear fechas a hora de Perú usando date-fns-tz
  const formatDatePeru = (date) => {
    return formatInTimeZone(date, 'America/Lima', 'HH:mm');
  };

  // Función para obtener la información de los clientes de la empresa
  const fetchClients = async () => {
    setLoadingClients(true);
    try {
      const res = await fetch(`https://yfw9wy8zsj.execute-api.us-east-1.amazonaws.com/dev/client/company/${COMPANY_ID}`);
      if (!res.ok) {
        throw new Error(`Error fetching clients for company ID: ${COMPANY_ID}`);
      }
      const data = await res.json();
      const clientsData = data.body ? JSON.parse(data.body) : [];

      console.log("clientsData", clientsData); // Para depuración

      // Aplanar cada cliente y convertir las fechas a objetos Date
      const flattenedClients = clientsData.map(flattenClient);

      setClients(flattenedClients);
    } catch (err) {
      console.error(err);
      setError('Error al cargar los clientes.');
    } finally {
      setLoadingClients(false);
    }
  };
  const fetchConversations = async (clientId) => {
    setLoadingConversations(true);
    try {
      const res = await fetch(`https://yfw9wy8zsj.execute-api.us-east-1.amazonaws.com/dev/conversations/user/${clientId}`);
      if (!res.ok) {
        throw new Error(`Error fetching conversations for client ID: ${clientId}`);
      }
      const data = await res.json();
  
      const conversations = data.body ? JSON.parse(data.body) : [];
      if (conversations.length === 0) {
        // En lugar de lanzar un error, simplemente retorna un array vacío
        return [];
      }
  
      const conversationId = conversations[0].Id;
  
      const messageRes = await fetch(`https://yfw9wy8zsj.execute-api.us-east-1.amazonaws.com/dev/messages/conversation/${conversationId}`);
      if (!messageRes.ok) {
        throw new Error(`Error fetching messages for conversation ID: ${conversationId}`);
      }
      const messageData = await messageRes.json();
      const messages = messageData.body ? JSON.parse(messageData.body) : [];
  
      // Parsear las fechas de los mensajes correctamente
      const parsedMessages = messages.map(msg => ({
        ...msg,
        CreatedAt: parseDateAsUTC(msg.CreatedAt),
      }));
  
      parsedMessages.sort((a, b) => a.CreatedAt - b.CreatedAt);
  
      return parsedMessages;
    } catch (err) {
      console.error(err);
      setError('Error al cargar las conversaciones.');
      return []; // Asegurar que se retorna un arreglo vacío en caso de error
    } finally {
      setLoadingConversations(false);
    }
  };
  
  const handleClientClick = async (clientId) => {
    setSelectedClientId(clientId);
    localStorage.setItem('selectedClientId', clientId);
    const messages = await fetchConversations(clientId);
    setConversations(messages);
  };

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    if (selectedClientId) {
      handleClientClick(selectedClientId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientId]);

  if (loadingClients) {
    return (
      <div className="flex h-screen items-center justify-center">
        <p>Cargando clientes...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex h-screen items-center justify-center text-red-500">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="flex h-screen">
  <div className="w-1/3 bg-gray-200 p-4 overflow-y-auto">
    <h2 className="text-xl font-bold mb-4">Clientes</h2>
    <ul>
      {clients
        .sort((a, b) => b.CreatedAt - a.CreatedAt)
        .map((client) => (
          <li
            key={client.Id}
            className={`p-2 mb-2 cursor-pointer rounded ${selectedClientId === client.Id ? 'bg-blue-300' : 'bg-white'
              }`}
            onClick={() => handleClientClick(client.Id)}
          >
            <p><strong>ID:</strong> {client.Id}</p>
            <p><strong>Número:</strong> {client.Phone}</p>
            <p className="text-xs text-gray-500">Conversación iniciada: {formatDatePeru(client.CreatedAt)}</p>
          </li>
        ))}
    </ul>
  </div>

  <div className="w-2/3 bg-white p-4 overflow-y-auto">
  {selectedClientId ? (
    <>
      <h2 className="text-xl font-bold mb-4">Conversación</h2>
      {loadingConversations ? (
        <p>Cargando conversación...</p>
      ) : conversations && conversations.length > 0 ? (
        <ul>
          {conversations.map((message, index) => (
            <li key={index} className="mb-2">
              <p
                className={`text-sm ${message.Role === 'assistant' ? 'text-blue-500' : 'text-green-500'
                  }`}
              >
                <strong>{message.Role === 'assistant' ? 'Asistente' : 'Usuario'}:</strong> {message.Text}
              </p>
              <p className="text-xs text-gray-500">
                {formatDatePeru(message.CreatedAt)}
              </p>
            </li>
          ))}
        </ul>
      ) : (
        <p>No se encontró ninguna conversación para este cliente.</p> // Aquí mostramos un mensaje amigable
      )}
    </>
  ) : (
    <p>Selecciona un cliente para ver la conversación.</p>
  )}
</div>

</div>

  );
};


const ChatItem = ({ avatar, name, message, time, unreadMessages }) => {
  return (
    <div className="flex items-center p-2 hover:bg-brand-dark-blue transition rounded-md">
      <img src={avatar} alt="avatar" className="w-12 h-12 rounded-full" />
      <div className="flex-1 ml-4">
        <div className="flex justify-between">
          <h2 className="text-white text-sm font-medium">{name}</h2>
          <span className="text-whatsapp-gray text-xs">{time}</span>
        </div>
        <div className="flex justify-between">
          <p className="text-whatsapp-gray text-sm">{message}</p>
          {unreadMessages > 0 && (
            <div className="bg-purple-900 text-white text-xs font-bold rounded-full h-5 w-5 flex items-center justify-center">
              {unreadMessages}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};


export default ConversationsPanel;
